<template>
  <ConfirmationRegistered class="mt-3" />
</template>

<script>
import ConfirmationRegistered from '@/components/cleanui/system/Auth/ConfirmationRegistered'

export default {
  components: {
    ConfirmationRegistered,
  },
}
</script>

<style>

</style>
