<template>
  <div v-if="loading === false" class="row">
    <div
      :class="{
        [$style.card__register]: true,
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <img
          src="https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482"
          width="170"
          height="82.13"
        />
      </div>

      <div class="pbz-font subtitle-md-medium mt-5 text-left" style="color: #1a1a1a;">
        Konfirmasi Undangan
      </div>

      <div class="pbz-font my-5 text-center">
        <div
          style="
            width: 100%;
            height: 48px;
            background: #FFFFFF;
            border: 1px solid #CCCCCC;
            box-sizing: border-box;
            border-radius: 80px;
            cursor: pointer;
            font-size: 13px;
          "
          class="d-flex justify-content-around align-items-center"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 10px 20px;
              width: 45%;
              height: 38px;
              color: #999999;
            "
            :style="[
              hasAccount && {
                background: '#E00000',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
                color: '#FFFFFF !important',
                borderRadius: '60px',
              }
            ]"
            class="pbz-font button-xs-medium"
            @click="handleHasAccount(true)"
          >
            Sudah Punya Akun
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 45%;
              height: 38px;
              color: #999999;
            "
            :style="[
              !hasAccount && {
                background: '#E00000',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
                color: '#FFFFFF !important',
                borderRadius: '60px',
              }
            ]"
            class="pbz-font button-xs-medium"
            @click="handleHasAccount(false)"
          >
            Belum Punya Akun
          </div>
        </div>
      </div>

      <div v-if="!hasAccount" class="mb-5">
        <a-form-model ref="ruleForm" :model="form" @submit.prevent="handleSubmit">
          <a-form-model-item
            v-if="emailInvited"
            prop="email"
            :class="{
              [$style.container__form]: true,
            }"
            :rules="[{ required: true }]"
          >
            <a-input
              v-model="form.email"
              :class="{
                [$style.container__form__input]: true,
                [$style.container__form__input__disabled]: emailInvited && true,
              }"
              disabled="disabled"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span
                :style="[
                  emailInvited && {
                    background: 'linear-gradient(#ffffff 0%, #e4e9f0 100%) no-repeat !important',
                  }
                ]"
              > Email </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            v-if="phoneInvited"
            prop="email"
            :class="{
              [$style.container__form]: true,
            }"
            :rules="[{ required: true }]"
          >
            <a-input
              v-model="form.email"
              :class="{
                [$style.container__form__input]: true,
                [$style.container__form__input__disabled]: phoneInvited && true,
              }"
              disabled="disabled"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span
                :style="[
                  phoneInvited && {
                    background: 'linear-gradient(#ffffff 0%, #e4e9f0 100%) no-repeat !important',
                  }
                ]"
              > No. Handphone </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="username"
            :class="{
              [$style.container__form]: true,
            }"
            :rules="[{ required: true }]"
          >
            <a-input
              v-model="form.username"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span> Nama Pengguna </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="name"
            :class="{
              [$style.container__form]: true,
            }"
            :rules="[{ required: true }]"
          >
            <a-input
              v-model="form.name"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span> Nama Lengkap </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="password"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('password'),
            }"
            :rules="[{
              required: true,
              validator: passwordValidator,
            }]"
          >
            <a-input-password
              v-model="form.password"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span> {{ $t('auth.password') }} </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="confirm_password"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('confirm_password'),
            }"
            :rules="[{
              required: true,
              validator: confirmPasswordValidator,
            }]"
          >
            <a-input-password
              v-model="form.confirm_password"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span> {{ $t('auth.confirmPassword') }} </span>
            </label>
          </a-form-model-item>

          <a-button
            size="large"
            class="pbz-font body-md-bold text-center w-100 mt-3"
            :class="{
              [$style.button__submit]: true,
            }"
            html-type="submit"
          >
            <div v-if="!loading">
              Registrasi
            </div>
            <div v-if="loading">
              <a-icon type="loading" /> Loading
            </div>
          </a-button>
        </a-form-model>
      </div>

      <div v-if="hasAccount" class="mb-5">
        <div class="mt-4">
          <a-form-model ref="ruleForm" :model="form" @submit.prevent="handleSubmit">
            <a-form-model-item
              prop="user_login"
              :class="{
                [$style.container__form]: true,
              }"
              :rules="[{
                required: true,
                message: $t('auth.invalidEmailPhoneNumber'),
              }]"
            >
              <a-input
                v-model="form.user_login"
                size="large"
                :placeholder="$t('auth.emailPhoneLabel')"
              />
              <label
                :class="{
                  [$style.label__form]: true,
                }"
                class="pbz-font overline-normal"
              >
                Username
              </label>
            </a-form-model-item>
            <a-form-model-item
              prop="password"
              :class="{
                [$style.container__form]: true,
              }"
              :rules="[{
                required: true,
                message: $t('auth.invalidPassword'),
              }]"
            >
              <a-input-password
                v-model="form.password"
                size="large"
                :placeholder="$t('auth.password')"
              />
              <label
                :class="{
                  [$style.label__form]: true,
                }"
                class="pbz-font overline-normal"
              >
                Kata Sandi
              </label>
            </a-form-model-item>
            <a-button
              size="large"
              class="pbz-font body-md-bold text-center w-100 mt-3"
              :class="{
                [$style.button__submit]: true,
              }"
              html-type="submit"
            >
              <strong> {{ $t('auth.login') }} </strong>
            </a-button>
          </a-form-model>
        </div>
      </div>

      <div class="mt-3 text-center">
        <div
          style="color: #666666 !important;"
          class="anotherSign pbz-font body-sm-medium"
        >
          {{ $t('auth.anotherRegister') }}
        </div>
      </div>

      <div
        :class="{
          [$style.anotherRegisterCard]: true,
        }"
        class="mt-5 mb-2"
      >
        <img class="mx-1" src="@/assets/PowerBiz/google-logo.png" />
        <div class="mx-3 pbz-font body-md-medium">
          {{ $t('auth.signUpWithGoogle') }}
        </div>
      </div>

      <div
        :class="{
          [$style.anotherRegisterCard]: true,
        }"
        class="mt-3 mb-2"
      >
        <img class="mx-1" src="@/assets/PowerBiz/facebook-logo.png" />
        <div class="mx-3 pbz-font body-md-medium">
          {{ $t('auth.signUpWithFacebook') }}
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mt-5 ">
    <div
      :class="{
        [$style.card__register]: true,
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <img
          src="@/assets/images/logo.svg"
          width="200"
          height="63"
        />
        <img
          class="my-3"
          src="@/assets/images/loading.gif"
          width="150"
        />
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center text-center" style="color: #1A1A1A !important">
        <div class="pbz-font subtitle-sm-medium">
          Please Wait . . .
          <br />
          Creating Your Workspace
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { Modal } from 'ant-design-vue';
import { h } from 'vue';
import Loading from 'vue-loading-overlay'
import { checkRegistration, inviteUserRegister } from '@/api/user'

export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      hasAccount: false,
      loading: false,
      form: {
        email: '',
        username: '',
        name: '',
        password: '',
        confirm_password: '',
        user_login: '',
      },
      emailInvited: '',
      phoneInvited: '',
      passwordValidator: (rule, value, callback) => {
        const { field } = rule
        this.passwordError.field = field

        const findErrorLengthMessage = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.minimumLengthPassword'),
        )
        const findErrorMessageOneUppercas = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.atLeastOneUppercase'),
        )
        const findErrorMessageOneNumber = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.atLeastOneNumber'),
        )

        if (!value) {
          !findErrorLengthMessage &&
            this.passwordError.error_message.push(
              this.$t('auth.minimumLengthPassword'),
            )
          !findErrorMessageOneUppercas &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneUppercase'),
            )
          !findErrorMessageOneNumber &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneNumber'),
            )
          return callback(this.$t('auth.minimumLengthPassword'))
        }

        const patternLength = /^.{6,}$/
        // const findErrorLengthMessage = this.passwordError.error_message.find(item => item === this.$t('auth.minimumLengthPassword'))

        if (!patternLength.test(value)) {
          !findErrorLengthMessage &&
            this.passwordError.error_message.push(
              this.$t('auth.minimumLengthPassword'),
            )
          return callback(this.$t('auth.minimumLengthPassword'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.minimumLengthPassword')) {
              newMessage.push(item)
            }
          })

          this.passwordError.error_message = newMessage
        }

        const patternOneUppercase = /(?=.*[A-Z])/
        // const findErrorMessageOneUppercas = this.passwordError.error_message.find(item => item === this.$t('auth.atLeastOneUppercase'))

        if (!patternOneUppercase.test(value)) {
          !findErrorMessageOneUppercas &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneUppercase'),
            )
          return callback(this.$t('auth.atLeastOneUppercase'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.atLeastOneUppercase')) {
              newMessage.push(item)
            }
          })
          this.passwordError.error_message = newMessage
        }

        const patternNumber = /(?=.*[0-9])/
        // const findErrorMessageOneNumber = this.passwordError.error_message.find(item => item === this.$t('auth.atLeastOneNumber'))

        if (!patternNumber.test(value)) {
          !findErrorMessageOneNumber &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneNumber'),
            )
          return callback(this.$t('auth.atLeastOneNumber'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.atLeastOneNumber')) {
              newMessage.push(item)
            }
          })

          this.passwordError.error_message = newMessage
        }

        if (this.passwordError.error_message.length) {
          return callback('error')
        } else {
          return callback()
        }
      },
      confirmPasswordValidator: (rule, value, callback) => {
        const { field } = rule
        this.confirmPasswordError.field = field
        const password = this.form.password

        const findErrorMessagePasswordNotMatch = this.checkExistingError(
          this.confirmPasswordError.error_message,
          this.$t('auth.passwordNotMatch'),
        )
        if (value !== password || !value) {
          !findErrorMessagePasswordNotMatch &&
            this.confirmPasswordError.error_message.push(
              this.$t('auth.passwordNotMatch'),
            )
          return callback(this.$t('auth.passwordNotMatch'))
        } else {
          this.confirmPasswordError.error_message = []
          return callback()
        }
      },
      passwordError: {
        field: null,
        error_message: [],
      },
      confirmPasswordError: {
        field: null,
        error_message: [],
      },
      idInvited: null,
    }
  },
  computed: {
    formError() {
      if (
        !this.passwordError.error_message?.length &&
        !this.confirmPasswordError.error_message?.length
      ) {
        return []
      }

      const data = []
      !!this.passwordError.error_message?.length &&
        data.push(this.passwordError)
      !!this.confirmPasswordError.error_message?.length &&
        data.push(this.confirmPasswordError)

      return data
    },
    keycloak() {
      return this.$keycloak
    },
  },
  mounted () {
    this.fetchVerificationToken()
  },
  methods: {
    handleHasAccount(param) {
      this.hasAccount = param
    },
    handleSubmit (e) {
      if (this.hasAccount) {
        e.preventDefault()
        this.loading = true
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$store
              .dispatch('user/SIGNINV2', this.form)
              .then(() => {
                this.$router.push({ path: '/dashboard' })
                this.loading = false
              })
              .catch((err) => {
                this.$notification.error({
                  message: this.$t('auth.loginFailed'),
                  description: err.response?.data?.message || err.message,
                })
                this.loading = false
              })
          }
        })
      }
      if (!this.hasAccount) {
        this.$refs.ruleForm.validate(valid => {
          if (!valid) return

          const payload = {
            id: this.idInvited,
            name: this.form.name,
            username: this.form.username,
            email: this.emailInvited,
            phone: this.phoneInvited,
            password: this.form.password,
          }

          this.$store.dispatch('user/SUBMITREGISTERUSERINVITATION', payload)
            .then(() => {
              this.$notification.success({
                message: 'Registrasi Sukses',
              })

              this.$router.push({
                path: '/auth/reg-conf/otp',
              })
            })
            .catch((err) => {
              // console.log()
              this.$notification.error({
                message: err.response.data.message,
              })
            })
        })
      }
    },
    modalLogout(key) {
      Modal.info({
        title: () => 'Mohon Untuk Logout Terlebih Dahulu!',
        content: () => h('div', {}, [
          h('p', 'Kemudian Klik Kembali Tautan Pada Email Untuk Konfirmasi.'),
        ]),
        onOk() {
          const url = new URL(window.location.href)
          url.pathname = '/auth/login'

          key.logout({
            redirectUri: url.href,
          })
        },
      });
    },
    async fetchVerificationToken () {
      this.loading = true
      const payload = { invite_token: this.$route.params.uuid }
      await inviteUserRegister({
        type: this.$route.path.includes('invite-owner')
          ? 'owner-register' 
          : 'register',
        data: payload,
        token: this.$keycloak.token,
      })
      .then(() => {
        if (this.$route.path.includes('invite-owner')) {
          let i = 0
          let delayedAfterWorkspaceReady = 15000
          const intervalCheckIsReady = setInterval(async () => {
            i+=1
            const { data: checkUserStatus } = await checkRegistration({
              invite_token: this.$route.params.uuid,
              token: this.$keycloak.token,
            })
            if (checkUserStatus?.data?.is_accepted) {
              clearInterval(intervalCheckIsReady)
              setTimeout(() => {
                this.loginActionAndDashboard()
              }, delayedAfterWorkspaceReady)
            }
            if (i > 30) {
              clearInterval(intervalCheckIsReady)
              this.$keycloak.logout()
              this.$router.push({
                path: '/confirmation/registered/timeout',
              })
            }
          }, 2000)
        } else {
          this.loginActionAndDashboard()
        }
      })
      .catch((err) => {
        console.error({err})
        if (err?.response?.data?.status === 400 && err.response.data.message === "Failed registering user") {
          Cookies.set('invite-user', null)
          return this.modalLogout(this.keycloak)
        }
        this.$notification.error({
          message: err?.response?.data?.message || '',
        })
      })
    },
    loginActionAndDashboard() {
      this.$store.dispatch('user/LOGINPROCESSING', {
        token: this.$keycloak.token,
      })
        .then(() => {
        })
        .catch(e => {
          console.error({e})
        })
        .finally(() => {
          this.loading = false
          this.$notification.success({
            message: 'Registrasi Sukses',
          })
          this.$router.push({
            path: '/confirmation/registered/success',
          })
        })
    },
    checkFormHaveError(paramater) {
      return this.formError.find((item) => item.field === paramater)
    },
    checkExistingError(errorLog = [], stringParam = '') {
      return errorLog.find((item) => item === stringParam)
    },
  },
}
</script>

<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
